import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router';
import { InfoContext } from './context';
import { Link } from 'react-router-dom';
import { updateMaildata } from '../js/api-service';

export default function ShowMessage() {

    const { session, data, mailData, editDateArchive } = useContext(InfoContext);
    let attachmentList, maildata;
    let { id } = useParams();
    id = parseInt(id);
    maildata = mailData && mailData.find(m => m.id === id);
    attachmentList = data && (data.find(m => m.maildata.id === id).attachmentlist || null);

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        if (!maildata?.dateArchive && maildata?.docType === "webportal") updateDateArchieve();
    }, [id])

    const updateDateArchieve = async () => {
        editDateArchive(id)
        const result = await updateMaildata(session, id);
    }

    const DownloadSVG = () => {
        return (
            <svg role="graphics-symbol" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="7 10 12 15 17 10" /><line x1="12" x2="12" y1="15" y2="3" /></svg>
        )
    }

    return (
        <>
            {session && data && mailData && <div>
                <h3 className="medium-header margin-bottom__small">Melding</h3>
                {maildata && <div className="message-block" dangerouslySetInnerHTML={{ __html: maildata.body }}></div>}

                {attachmentList.length === 0 ?
                    <h4 className="normal-header margin-bottom__large">Ikke noe vedlegg!</h4>
                    :
                    <>
                        <h4 className="normal-header margin-bottom__large">Vedlagte filer</h4>
                        {attachmentList.map((attachment, index) => {

                            // if (index === 1) {
                            //     return (<p className="attachment-line" key={attachment.id}><span>{attachment.name}</span><a href={`http://localhost:19565/doclist/download/?Id=3065&&Token=${session}`}>Laste ned hack</a></p>)
                            // } else
                            return (
                                <p className="attachment-line" key={attachment.id}><span>{attachment.name}</span><a href={`${process.env.REACT_APP_API_URL}/doclist/download/se/?Id=${attachment.id}&&Token=${session}`} ><DownloadSVG />Last ned</a></p>
                            )
                        })}
                    </>
                }

                <div className="link-block">
                    <Link to="/">Gå tillbaka</Link>
                </div>
            </div>
            }
        </>
    )
}
