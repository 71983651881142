import React, { useState, useContext } from 'react';
import { InfoContext } from '../context'

export default function Filed({ item, isTextarea = false }) {
    const { name, type, placeholder, required = false, disabled = false, label, pattern, value, isValidCheck } = item;
    let { error } = item;
    const [valid, setValid] = useState(false);
    const { updateFormData } = useContext(InfoContext);

    if (required) {
        error = error ?? "Detta fält är obligatoriskt";
    }

    const handleChange = (event) => {
        if (event.target.checkValidity()) {
            setValid(true)
        }
        else {
            setValid(false)
        }
    }

    const handleBlur = (event) => {
        updateFormData(name, event.target.value.trim(), item?.isBankIdForm)
    }

    if (!isTextarea) {
        return (
            <div>
                <label className="input-label">
                    <span className={`${required ? "required" : ""}`}> {label}</span>
                    <input value={value} pattern={pattern ?? ".*"} name={name ?? ""} type={type ?? "text"} placeholder={placeholder ?? ""} required={required} disabled={disabled} onChange={(event) => { handleChange(event) }} onBlur={(event) => { handleBlur(event) }} />
                </label>
                {isValidCheck && required && !value && <p id={`error-message-${name}`} className={`${valid ? 'no-display' : ''} error-message `} >{error ?? ""}</p>}
            </div >
        )
    } else {
        return (
            <div>
                <label className="input-label">
                    <span className={`${required ? "required" : ""}`}> {label}</span>
                    <textarea name={name ?? ""} placeholder={placeholder ?? ""} required={required} onChange={(event) => { handleChange(event) }} onBlur={(event) => { handleBlur(event) }}></textarea>
                </label>
                {isValidCheck && required && <p id={`error-message-${name}`} className={`${valid ? "no-display" : ""} error-message`}>{error ?? ""}</p>}
            </div>
        )
    }
}
