import React from 'react'


export default function Fail() {

    return (
        <>
            <h3 className='medium-header'>Feil</h3>
            <div className="link-block">
                <a href={process.env.REACT_APP_ADDRESS}>Gå tillbaka</a>
            </div>
        </>
    )
}
