import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRedirectUrl } from '../js/api-service';
import { InfoContext } from './context';
import { getSmsCode, checkSmsCode } from '../js/api-service';


export default function DefaultHome() {

    const { updateFail, smsConfirm, setSmsConfirm, updateSessionStorage } = useContext(InfoContext)
    const [email, setEmail] = useState("");
    const [claimNumber, setClaimNumber] = useState("");
    const [smsCode, setSmsCode] = useState("");
    const [smsResult, setSmsResult] = useState();
    const [failMessage, setFailMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onLoginClick = async (event) => {

        event.preventDefault();
        const selectedVlue = event.target.value;
        if (selectedVlue) {
            updateSessionStorage();
            try {
                const data = await getRedirectUrl(selectedVlue);
                if (data.redirectUri) {
                    window.location.href = data.redirectUri;
                }
            } catch (error) {
                updateFail(true);
            }
        }
    }

    const handleClaimSubmit = async (event) => {
        event.preventDefault();
        if (claimNumber === "" || email === "") return;
        setLoading(true);
        updateSessionStorage();
        try {
            const result = await getSmsCode(claimNumber, email);
            if (!result) {
                setFailMessage(true);
            } else {
                setSmsConfirm(true);
                setSmsResult(result);
                setFailMessage(false);
            }
        } catch {
            setFailMessage(true);
        }
        setLoading(false);
    }

    const handleSmsCheck = async (event) => {

        event.preventDefault();
        if (smsCode === "") return;
        setLoading(true);
        try {
            const result = await checkSmsCode(smsCode, smsResult?.currentTime);
            if (!result) {
                setFailMessage(true);
            } else {
                setSmsConfirm(false);
                setFailMessage(false);
                navigate(`/?Session=${result}`, { state: { session: result } });
            }
        } catch {
            setFailMessage(true);
        }
        setLoading(false);
    }

    return (
        <>
            {loading && <div className="loading" aria-label="Vennligst vente."></div>}
            {!loading && <>
                <h2 className="large-header align-center portal-title">Välkommen till Crawfordportalen</h2>
                <div className="home-container smslogin">
                    {!smsConfirm ?
                        <>
                            <p>Välj inloggningsmetod</p>
                            <button role="link" className="loginbutton loginbutton__sebankid" type="button" onClick={onLoginClick} value="urn:grn:authn:se:bankid:another-device:qr" >Logga in med BankID </button>
                            <br />
                            <p>Om du har fått ett meddelande om att logga in med en kod via SMS anger du ditt ärendenummer och mejl nedan</p>
                            <form name='claimForm' onSubmit={(event) => { handleClaimSubmit(event) }}>
                                <input type='text' name='claimNumber' placeholder='Ärendenummer' aria-label='Ärendenummer' required onChange={(event) => setClaimNumber(event.target.value.trim())} />
                                <input type='email' name='email' placeholder='Epost' aria-label='Epost' required onChange={(event) => setEmail(event.target.value.trim())} />
                                <button type='submit' className='submit-button' disabled={claimNumber === "" || email === ""} >Submit</button>
                                {failMessage && <p className='error-message'>Inget ärende hittades med ärendenummer och mail som du har angett!</p>}
                            </form>
                        </>
                        :
                        <>
                            <p>Vänligen skriv in koden vi skickat till {smsResult?.mobile}</p>
                            <form name='smsForm' onSubmit={(event) => { handleSmsCheck(event) }}>
                                <input type='text' autoFocus name='smsCode' placeholder='Sms code' aria-label='Sms code' pattern="[0-9]{6}$" required onChange={(event) => setSmsCode(event.target.value.trim())} inputMode='numeric' />
                                <button type='submit' className='submit-button' disabled={smsCode === "" || smsCode.length !== 6} >Send inn</button>
                                {failMessage && <p className='error-message'>Det uppstod ett fel! Vänligen prova igen eller kontakta oss.</p>}
                            </form>
                        </>
                    }
                </div>
            </>
            }
        </>
    )
}
