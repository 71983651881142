import React from 'react';

export default function LogoutSpinner() {
    return (
        <div className="spinner">
            <div className="spinner__content" role="alert">
                <span aria-hidden="true" className="spinner__animation"></span>
                <h5 className="small-header">Logging ut</h5>
            </div>
        </div>
    )
}
