import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { InfoContext } from '../components/context';

export default function Landing() {

    const { name, data, isBankID, mailData, parterList } = useContext(InfoContext);

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, [])

    const getAttachmentLength = (id) => {
        let count = data.map(mdata => {
            if (mdata.maildata.id === id)
                return mdata.attachmentlist.length;
        })
        return count;
    }

    return (
        <>
            {!name && <div className="loading" aria-label="Vennligst vente."></div>}
            <div>
                {name && <h3 className="medium-header">Hei {name},</h3>}
                {isBankID && <p >Klicka <Link className='semiBold color-blue' to="new-case">här</Link> om du vill rapportera ett nytt ärende.</p>}
            </div>

            {data && mailData && <>
                <p >Klicka för att läsa meddelandet och ladda ner dokumentet</p>
                <div className="table" role="table" aria-label="Sendt post.">
                    <div className="table__row table__row__header" role="row">
                        <div className="table__cell title" role="columnheader">Datum</div>
                        <div className="table__cell title" role="columnheader">Ärende</div>
                        <div className="table__cell title" role="columnheader">Ämne</div>
                        <div className="table__cell title" role="columnheader">Status</div>
                        <div className="table__cell title" role="columnheader">Bilagor</div>
                        <div className="table__cell title" role="columnheader" aria-hidden="true">Öppna</div>
                    </div>
                    {mailData && mailData.map((maildata, index) => {
                        return (
                            <Link className={`table__row ${!maildata.dateArchive && maildata?.docType === "webportal" ? "bold" : ""}`} key={index} to={`/message/${maildata.id}`} aria-label="Trykk for å lese dokumentet.">
                                <div className="table__cell" role="cell">{maildata.dateReceived.split("T")[0]}</div>
                                <div className="table__cell" role="cell">{maildata.claimid}</div>
                                <div className="table__cell" role="cell">{maildata.mailsubject}</div>
                                <div className={`table__cell ${maildata.docType === "webopplastning" ? "color-red" : "color-green"}`} role="cell">{maildata.docType === "webopplastning" ? "skickas" : "Mottagen"}</div>
                                <div className="table__cell" role="cell">{getAttachmentLength(maildata.id)}</div>
                                <div className="table__cell" role="cell" aria-hidden="true"><span className="action-button action-button__show"></span></div>
                            </Link>
                        )
                    })}
                </div>
            </>
            }
            <br /><br />
            {parterList && <>
                <p >Klicka för att ladda upp dokument eller skicka meddelanden</p>
                <div className="table" role="table" aria-label="Document list">
                    <div className="table__row table__row__header" role="row">
                        <div className="table__cell title" role="columnheader">Ärendenummer</div>
                        <div className="table__cell title" role="columnheader">Namn</div>
                        {/* <div className="table__cell title" role="columnheader">SystemID</div> */}
                        <div className="table__cell title" role="columnheader">Mejladress</div>
                        <div className="table__cell title" role="columnheader" aria-hidden="true">Svara</div>
                    </div>
                    {parterList && parterList.map((item, index) => {
                        return (
                            <Link className="table__row" role="row" key={index} to={`/upload/${item.claimNumber}`} aria-label="Click for upload document or send message">
                                <div className="table__cell" role="cell">{item.claimNumber}</div>
                                <div className="table__cell" role="cell">{item.navn}</div>
                                {/* <div className="table__cell" role="cell">{item.systemID}</div> */}
                                <div className="table__cell" role="cell">{item.epost}</div>
                                <div className="table__cell" role="cell" aria-hidden="true"><span className="action-button action-button__reply"></span></div>
                            </Link>
                        )
                    })}

                </div>
            </>
            }
        </>
    )
}
