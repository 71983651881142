import React from 'react'

export default function Footer() {
    return (
        <footer>
            <div className="contact">
                <p className="lead">Crawford & Company (Sweden) AB</p>
                <p> Org nr 556033-6793  | <a href="https://www.crawco.com/en-se">crawco.se</a> | <span className="mobile-display-block">Tlf. <a href="tel:67552500" aria-label="Telefonnummer">67 55 25 00</a></span>
                </p>
                <br />
                <p className="copyright">©2024 CRAWFORD & COMPANY</p>
            </div>
        </footer>
    )
}

