import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import FileList from './custom/FileList';
import { InfoContext } from '../components/context';
import { postMaildata, postAttachment, getMaildataList } from '../js/api-service';


export default function Upload() {

    const { session, parterList, formFiles, name, updateFormFiles, personNumber, setData, setMailData } = useContext(InfoContext);
    const [comment, setComment] = useState("");
    const [completeMessage, setCompleteMessage] = useState(false);
    const [failMessage, setFailMessage] = useState(false);
    const [loading, setloading] = useState(false);
    let { id } = useParams();
    let item = parterList?.find(p => p.claimNumber === id);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, [])

    useEffect(() => {
        setFailMessage(false);
    }, [formFiles, comment])

    const ignoreTimeOffset = () => {
        const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
        const localIsoTime = (new Date(Date.now() - timeZoneOffset)).toISOString().slice(0, -1);
        return localIsoTime;
    }

    const handleUploadSubmit = async (event) => {

        event.preventDefault();
        let maildataResult = "", attachmentResult = "";
        if (comment === "") {
            setFailMessage(true);
            return;
        }
        setloading(true);
        setFailMessage(false);
        const date = ignoreTimeOffset();
        const envelopId = `${session.split("-")[0]}${new Date().valueOf()}`;
        const maildata = {
            DelegateTo: parseInt(item?.adjusterID),
            DateInserted: date,
            DateReceived: date,
            DateSent: date,
            MailFrom: personNumber,
            MailTo: "",
            Mailsubject: `Tilcrawford SE registrert`,
            Body: comment,
            Direction: 'In',
            DocType: 'webopplastning',
            SystemId: item?.systemID,
            BodyFormat: 1,
            CountAttachment: formFiles ? formFiles?.length : 0,
            InternalComment: envelopId,
            Claimid: item?.claimNumber,
            PartyName: name,
            Client: ""
        };
        try {
            maildataResult = await postMaildata(session, maildata);
        } catch {
            navigate("/fail");
        }
        if (formFiles && formFiles?.length > 0) {
            let files = Array.from(formFiles);
            for (let index = 0; index < files.length; index++) {
                const fileElement = files[index];
                try {
                    attachmentResult = await postAttachment(session, fileElement, envelopId);
                } catch {
                    navigate("/fail");
                }
            }
            updateFormFiles([]);
        }

        const mailDataList = await getMaildataList(session);
        setData(mailDataList?.data)
        let newMailData = mailDataList?.data?.map(res => res.maildata);
        setMailData(newMailData);

        if (maildataResult !== "") setCompleteMessage(true);
        setloading(false);
    }

    return (
        <>
            {loading && <div className="loading" aria-label="Vänta"></div>}
            {!loading && !completeMessage ?
                <>
                    <h3 className="medium-header margin-top__large" >Du kan skicka ett meddelande och dokumentation här</h3 >
                    <form name='claimForm' onSubmit={(event) => { handleUploadSubmit(event) }}>
                        <div>
                            <label className="input-label">
                                <span className='required'>Vänligen skriv ditt meddelande</span>
                                <textarea name="comment" placeholder="Skriv ditt meddelande" onChange={(event) => { setComment(event.target.value.trim()) }} ></textarea>
                            </label>
                        </div>
                        <p>Tryck för att välja filer eller dra dem till filknappen</p>
                        <FileList item={{ text: "Välj fil", name: "attachments", required: false, multiple: true }} />
                        {failMessage && <p className='error-message margin-top__large'>Du måste skriva en text</p>}
                        <button type='submit' className='submit-button margin-top__large padding-small'>Skicka in</button>
                    </form >
                </>
                :
                <h3 className="medium-header margin-top__large" >Tack för ditt meddelande!</h3>
            }
            {!loading && <div className="link-block">
                <Link to="/">Gå tillbaka</Link>
            </div>
            }
        </>
    )

}