import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Fail from '../src/components/Fail';
import ShowMessage from '../src/components/ShowMessage';
import Home from '../src/components/Home';
import Upload from '../src/components/Upload';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NewCase from './components/NewCase';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Fail />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "message/:id",
        element: <ShowMessage />,
      },
      {
        path: "upload/:id",
        element: <Upload />,
      },
      {
        path: "/fail",
        element: <Fail />,
      },
      {
        path: "/new-case",
        element: <NewCase />,
      },
      {
        path: "*",
        element: <App />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
